import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"

const PAGE_TITLE = "Partners and affiliation"
const PAGE_NAME = "partners"
const PartnersPage = props => {
  return (
    <Layout pageName={PAGE_NAME} headerTitle={PAGE_TITLE}>
      <SEO title={PAGE_TITLE} />
      <section id="about-us" className="container">
        <div className="row">
          <div className="col-sm-6">
            AJVDC is calling for all interested parties to get involved and to
            work in partnership in our programs. Our current partners include:
            <ul>
   			  <li><a href="www.umuntumovement.org">Umuntu Movement Swizterland</a></li>
			  <li><a href="www.ewayfoundation.org">Eway foundation</a></li>
              <li><a href="http://www.internationalcitiesofpeace.org">International Cities of peace</a></li>
              <li><a href="http://www.pragmora.com">Pragmora</a></li>
              <li><a href="http://www.byakagroup.net">BYAKA Group</a></li>
              <li><a href="http://www.gamip.org/">GAMIP</a></li>
			  <li><a href="www.fhi360.org">FHI360 (Family Health International) through USAID</a></li>
            </ul>
          </div>
          <div className="col-sm-6">
            <Image imageKey={"image8"} />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PartnersPage
